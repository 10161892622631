import { debounce } from "debounce";
import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Breadcrumbs extends Component {
  static settings = {
    isResponsive: false,
    control: '.js-breadcrumbs-control',
    target: '.js-breadcrumbs-target',
  };

  #control;
  #target;
  #enabled;
  #hasEnded = false;
  #handleControlClick;
  #handleWindowDebouncedResize;

  constructor (element, options = {}) {
    super(element, {
      ...Breadcrumbs.settings,
      ...options
    });

    this.#control = this.getNode(this.settings.control);
    this.#target = this.getNode(this.settings.target);

    if (!this.#control) {
      throw new Error(`Breadcrumbs error: A control with the '${ this.settings.control }' selector is missing.`);
    }

    if (!this.#target) {
      throw new Error(`Breadcrumbs error: A target with the '${ this.settings.target }' selector is missing.`);
    }

    if (!this.#control.getAttribute('id')) {
      throw new Error(`Breadcrumbs error: The control needs an id to work correctly.`, this.#control);
    }

    if (!this.#target.getAttribute('id')) {
      throw new Error(`Breadcrumbs error: The target needs an id to work correctly.`, this.#target);
    }

    this.#handleControlClick = this._handleControlClick.bind(this);
    this.#handleWindowDebouncedResize = debounce(this._handleWindowDebouncedResize.bind(this), 20);
  }

  get control () {
    return this.#control;
  }

  get target () {
    return this.#target;
  }

  get expanded () {
    return this.#control.getAttribute('aria-expanded') === 'true';
  }

  get expandable () {
    return getComputedStyle(this.control).display !== 'none';
  }

  get enabled () {
    return this.#enabled;
  }

  get hasEnded () {
    return this.#hasEnded;
  }

  get windowWidth () {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  _handleWindowDebouncedResize () {
    // If the window width has changed since last time
    // (it's not necessary to update breadcrumbs state when the resize is on the window height)
    if (this.tempWidth !== this.windowWidth) {
      this.update();

      // Update temporary with new window width
      this.tempWidth = this.windowWidth;
    }
  }

  _handleControlClick (e) {
    this.open();
  }

  open () {
    this.#control.setAttribute('aria-expanded', 'true');
    this.#target.removeAttribute('hidden');

    if (this.settings.isResponsive) {
      this.unsetResponsive();
    }

    this.element.classList.add('has-ended');
    this.unmount();
    this.#hasEnded = true;
    this.target.focus();
    this.control.remove();

    this.fire('open', this);
  }

  update () {
    // hasEnded is at true when control has been clicked
    if (!this.hasEnded) {
      if (this.expandable && !this.#enabled) {
        this.enable();
      } else if (!this.expandable && this.#enabled) {
        this.disable();
      }
    }
  }

  setResponsive () {
    this.tempWidth = 0;
    window.addEventListener("resize", this.#handleWindowDebouncedResize);

    if (!this.hasEnded) {
      if (this.expandable && !this.#enabled) {
        this.enable();
      } else if (!this.expandable && this.#enabled) {
        this.disable();
      }
    }

    this.fire('setResponsive', this);
  }

  unsetResponsive () {
    delete this.tempWidth;
    window.removeEventListener("resize", this.#handleWindowDebouncedResize);

    this.fire('unsetResponsive', this);
  }

  enable () {
    this.#target.setAttribute('aria-labelledby', this.#control.getAttribute('id'));
    this.#target.setAttribute('hidden', '');
    this.#control.setAttribute('aria-expanded', 'false');
    this.#control.setAttribute('aria-controls', this.#target.getAttribute('id'));

    this.#control.addEventListener('click', this.#handleControlClick);

    this.#enabled = true;

    this.fire('enable', this);
  }

  disable () {
    this.#control.removeEventListener('click', this.#handleControlClick);

    this.#control.removeAttribute('aria-expanded');
    this.#control.removeAttribute('aria-controls');
    this.#target.removeAttribute('aria-labelledby');
    this.#target.removeAttribute('hidden');

    this.#enabled = false;

    this.fire('disable', this);
  }

  mount () {
    super.mount();

    if (this.settings.isResponsive && this.tempWidth === undefined) {
      this.setResponsive();
    } else {
      this.enable();
    }
  }

  unmount () {
    if (this.settings.isResponsive) {
      this.unsetResponsive();
    }

    this.disable();

    super.unmount();
  }
}
