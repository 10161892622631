import "./front/components/header/lifecycle";
import './front/components/Breadcrumbs/lifecycle';
import './front/components/Details/lifecycle';
import './front/components/Slider/lifecycle';
import './front/components/Dropdown/lifecycle';
import './front/components/Filter/lifecycle';
import './front/components/Apply/lifecycle';
import './front/components/Form/lifecycle';
import './front/components/Stickymenu/lifecycle';
import './front/components/Banner/lifecycle';

let scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

window.addEventListener('resize', function(event) {
  let scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);
}, true);
