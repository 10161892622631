/**
 * Name: Seemore
 * Description: Collapsing section
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Apply extends Component {
  static settings = {
  };

  #select;
  #selectedValue;
  #folderList;
  #folderButton;
 #folderButtonLabel

  #handleChange;

  constructor (element, options = {}) {
    super(element, {
      ...Apply.settings,
      ...options
    });

    this.#select = this.element.querySelector("select");
    this.#handleChange = this._handleChange.bind(this);
    this.#folderList = this.element.querySelectorAll(".js-apply-folder__file");
    this.#selectedValue = 0;
    this.#folderButton = this.element.querySelector(".js-apply-folder__button");
    this.#folderButtonLabel = this.element.querySelector(".js-apply-folder__button .c-button__label");
  }

  _handleChange() {
    this.#selectedValue = this.#select.value;

    this.#folderButton.href=this.#folderList[(this.#selectedValue - 1)].getAttribute("data-url");

    this.#folderButtonLabel.innerText = "Télécharger le dossier (" + this.#folderList[(this.#selectedValue - 1)].getAttribute("data-subtype") +"-"+ this.#folderList[(this.#selectedValue - 1)].getAttribute("data-size") +")"

  }

  mount () {
    this.#select.addEventListener('change', this.#handleChange);
    super.mount();
  }

  unmount () {

    super.unmount();
  }
}
