import 'mdn-polyfills/NodeList.prototype.forEach';
import DataGouvSearch from '../dataGouv/DataGouv';

export default class Filter {
  constructor(element) {
    this.element = element;

    this.handelLocation = this.handelLocation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.geoInput = document.querySelector('.js-location-input');
    this.geoButton = document.querySelector('.js-location-button');

    this.geoLatitude = document.querySelector('.js-latitude');
    this.geoLongitude = document.querySelector('.js-longitude');
  }

  mount() {
    if ("geolocation" in navigator) {
      this.geoButton.removeAttribute('aria-hidden');
      this.geoButton.addEventListener('click', this.handelLocation);
    }else {
      this.geoButton.style.display="none";
    }

    const dataGouvSearchElement = document.getElementById('autocomplete-datagouv-search');
    if(dataGouvSearchElement) {
      this.dataGouvSearch = new DataGouvSearch(dataGouvSearchElement);
      this.dataGouvSearch.mount();
    }

    this.element.addEventListener('submit', this.handleSubmit);
  }

  handelLocation(position) {
    const success = (position) => {
      this.geoInput.value = 'Ma position actuelle';
      this.geoLatitude.value= position.coords.latitude;
      this.geoLongitude.value= position.coords.longitude;

    };

    const error = (position) => {
      this.geoInput.value = 'Impossible de retrouver la localisation';
    };

    navigator.geolocation.getCurrentPosition(success, error);
  }

  handleSubmit(e) {

    if (this.dataGouvSearch.menuOpen) {
      e.preventDefault();
    } else {
    }
  }
}
