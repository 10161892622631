/**
 * Name: Seemore
 * Description: Collapsing section
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Form extends Component {
  static settings = {
  };

  #element;
  #formOptions;
  #formControler;
  #formControlerSelect
  #formControlerOptions;
  #handleChange;

  #showForm;

  constructor (element, options = {}) {
    super(element, {
      ...Form.settings,
      ...options
    });

    this.#element = this.element;
    this.#handleChange = this._handleChange.bind(this);
    this.#formOptions = this.element.querySelectorAll(".js-form-form");
    this.#formControler = this.element.querySelector(".js-form-controler");
    this.#formControlerSelect = this.element.querySelector(".js-form-controler select");
    this.#formControlerOptions = this.element.querySelectorAll(".js-form-controler option");
    this.#showForm = this._showForm.bind(this);

  }

  _handleChange() {
    this.#showForm();
  }

  _showForm() {
    this.#formOptions.forEach(element => {
      element.setAttribute("aria-hidden","true");
      // affiche cela qui match avec selecte value du js-form-form
      if (element.getAttribute("data-option") == this.#formControlerSelect.value ) {
        element.setAttribute("aria-hidden", "false");
        this.#element.setAttribute("data-value", this.#formControlerSelect.value);
      }
    })
  }

  mount () {
    this.#formControler.addEventListener('change', this.#handleChange);
    super.mount();
  }

  unmount () {

    super.unmount();
  }
}
