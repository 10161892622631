import 'mdn-polyfills/NodeList.prototype.forEach';
import Banner from './Banner';

document.addEventListener('DOMContentLoaded', () => {
  const banners = document.querySelectorAll('.js-banner');
  if (!banners.length) {
    return;
  }

  banners.forEach((element) => {
    const banner = new Banner(element);
    banner.mount(element);
  });
});
