import Slider from './Slider';

document.addEventListener('DOMContentLoaded', () => {
  const sliderElements = document.querySelectorAll('.js-slider-control');
  if (!sliderElements.length) {
    return;
  }
  sliderElements.forEach((element) => {
    const sliderElement = new Slider(element, {
      hasAdditionalControl: true,
    });
    sliderElement.mount();
  });
});
