import Form from './Form';

document.addEventListener('DOMContentLoaded', () => {
  const formElements = document.querySelectorAll('.js-form');
  if (!formElements.length) {
    return;
  }
  formElements.forEach((element) => {
    const formElement = new Form(element, {
      hasAdditionalControl: true,
    });
    formElement.mount();
  });
});
