
import Dropdown from './Dropdown';

const dropdownElements = document.querySelectorAll('.js-dropdown');
if (dropdownElements.length) {
  dropdownElements.forEach((dropdownElement) => {
    const dropdown = new Dropdown(dropdownElement);
    dropdown.mount();
  });
}
