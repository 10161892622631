/**
 * Name: Seemore
 * Description: Collapsing section
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Slider extends Component {
  static settings = {
  };

  #control;
  #selectedId;
  #sliderImages;


  #handleControlClick;
  #handleControlFocus;

  constructor (element, options = {}) {
    super(element, {
      ...Slider.settings,
      ...options
    });

    this.#control = this.element;
    this.#selectedId = 1;
    this.#sliderImages = document.querySelectorAll(".c-slider__figure");

    this.#handleControlClick = this._handleControlClick.bind(this);
    this.#handleControlFocus = this._handleControlFocus.bind(this);
  }

  _handleControlClick (e) {

    this.#sliderImages.forEach(element => {
      element.style.opacity="0"
    });

    if (this.#control.id) {
      this.#selectedId = this.#control.id;
    }

    this.#sliderImages[(this.#selectedId - 1)].style.opacity="1"
  }

  _handleControlFocus (e) {

    if (e.target && this.#control.contains(e.target)) {

      this.#sliderImages.forEach(element => {
        element.style.opacity="0"
      });


      if (this.#control.id) {
        this.#selectedId = this.#control.id;
      }

      this.#sliderImages[(this.#selectedId - 1)].style.opacity="1"
    }
  }

  mount () {
    this.#control.addEventListener('mouseover', this.#handleControlClick);
    document.addEventListener('focusin', this.#handleControlFocus);
    super.mount();
  }

  unmount () {

    super.unmount();
  }
}
