import Apply from './Apply';

document.addEventListener('DOMContentLoaded', () => {
  const applyElements = document.querySelectorAll('.js-apply-folder');
  if (!applyElements.length) {
    return;
  }
  applyElements.forEach((element) => {
    const applyElement = new Apply(element, {
      hasAdditionalControl: true,
    });
    applyElement.mount();
  });
});
