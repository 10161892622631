import 'mdn-polyfills/NodeList.prototype.forEach';
import Stickymenu from './Stickymenu';

document.addEventListener('DOMContentLoaded', () => {
  const stickymenus = document.querySelectorAll('.js-stickymenu');
  if (!stickymenus.length) {
    return;
  }

  stickymenus.forEach((element) => {
    const stickymenu = new Stickymenu(element);
    stickymenu.mount(element);
  });
});
