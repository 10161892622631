import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Banner {
  constructor(element) {
    this.element = element;
    this.button = document.querySelector(".js-banner-button");

    this.handelClose = this.handelClose.bind(this);
  }

  mount() {
    this.button.addEventListener('click', this.handelClose );

    if (sessionStorage.getItem("banner")) {
      if (sessionStorage.getItem("banner") == "closed") {
        this.element.style.display="none";
      }
    }
  }

  handelClose(event) {
    sessionStorage.setItem("banner", "closed");
    this.element.style.display="none";
  }

  unmount() {
  }
}
