import Filter from './Filter';

document.addEventListener('DOMContentLoaded', () => {
  const filterElement = document.querySelector('.js-filter');

  if (filterElement) {
    const filter = new Filter(filterElement);
    filter.mount();
  }
});
